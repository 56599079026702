import { TimelineEvent } from "@evercam/ui"
import {
  ProjectExid,
  TimelapseFilterQueryParams,
  TimelapseReportType,
  TimelapseType,
  TimelineDateInterval,
  TimelineProviderRequestParams,
} from "@evercam/shared/types"
import { TimelineDataProvider } from "./timelineDataProvider"
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"

type EventsPromises = Record<
  TimelapseReportType,
  Promise<Array<TimelineEvent & Record<string, unknown>>>
>
type CountsPromises = Record<
  TimelapseReportType,
  Promise<Array<TimelineEvent & Record<string, unknown>>>
>

export class TimelineTimelapseReportsProvider extends TimelineDataProvider {
  private static eventsPromises: EventsPromises = {} as EventsPromises
  private static countsPromises: CountsPromises = {} as CountsPromises
  readonly reportType: TimelapseReportType
  readonly requestParams = {}
  readonly projectExid: ProjectExid
  readonly eventsFilter: ((c: unknown) => boolean) | null
  readonly countsFilter: ((c: unknown) => boolean) | null

  constructor(params: {
    timezone: string
    projectExid: ProjectExid
    reportType: TimelapseReportType
    eventsFilter?: ((c: Record<string, unknown>) => boolean) | null
    countsFilter?: ((id: string) => boolean) | null
    requestParams?: Partial<TimelapseFilterQueryParams>
  }) {
    super(params.timezone)
    this.projectExid = params.projectExid
    this.reportType = params.reportType
    this.eventsFilter = params.eventsFilter || null
    this.countsFilter = params.countsFilter || null
    this.requestParams = params.requestParams || {}
  }

  async fetchEvents(
    params: TimelineDateInterval
  ): Promise<Array<TimelineEvent>> {
    if (!TimelineTimelapseReportsProvider.eventsPromises[this.reportType]) {
      TimelineTimelapseReportsProvider.eventsPromises[this.reportType] =
        this.doFetchEvents(params).finally(() => {
          TimelineTimelapseReportsProvider.eventsPromises[this.reportType] =
            null
        })
    }

    const events = await TimelineTimelapseReportsProvider.eventsPromises[
      this.reportType
    ]

    if (typeof this.eventsFilter === "function") {
      return events.filter(this.eventsFilter)
    } else {
      return events
    }
  }

  async fetchCounts(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineEvent>> {
    if (!TimelineTimelapseReportsProvider.countsPromises[this.reportType]) {
      TimelineTimelapseReportsProvider.countsPromises[this.reportType] =
        this.doFetchCounts(params).finally(() => {
          TimelineTimelapseReportsProvider.countsPromises[this.reportType] =
            null
        })
    }

    return TimelineTimelapseReportsProvider.countsPromises[
      this.reportType
    ].then((counts) => {
      return counts.map(({ date, counts }) => ({
        timestamp: this.formatTimestamp(date),
        count: Object.entries(counts).reduce((acc, [category, count]) => {
          if (this.countsFilter && this.countsFilter(category)) {
            return acc + Number(count)
          } else {
            return acc
          }
        }, 0),
      }))
    })
  }

  private async doFetchEvents(
    params: TimelineDateInterval
  ): Promise<Array<TimelineEvent>> {
    const items = await EvercamLabsApi.timelapseReports.getReports({
      projectExid: this.projectExid,
      fromDate: params.fromDate,
      toDate: params.toDate,
      reportType: this.reportType,
      timelapseType: TimelapseType.Hour,
      confidence: 90,
      ...this.requestParams,
    })

    return items.map((e) => ({
      id: e.id,
      activityType: e.activityType,
      startDate: this.formatTimestamp(e.fromDate),
      region: e.region,
      originalStartDate: e.fromDate,
      endDate: this.formatTimestamp(e.toDate),
      originalEndDate: e.toDate,
      timestamp: this.formatTimestamp(e.fromDate),
      text: e.title,
      description: e.description,
      size: 18,
      milestoneType: this.reportType,
      timelapseId: e.timelapseId,
    }))
  }

  private async doFetchCounts(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineEvent>> {
    return await EvercamLabsApi.timelapseReports.getReportsCounts({
      projectExid: this.projectExid,
      fromDate: params.fromDate,
      toDate: params.toDate,
      precision: params.precision,
      reportType: this.reportType,
      timelapseType: TimelapseType.Hour,
      ...this.requestParams,
    })
  }
}
