import { TimelineCountEvent, TimelineEvent } from "@evercam/ui"
import axios from "@evercam/shared/api/client/axios"
import { AiApi } from "@evercam/shared/api/aiApi"
import {
  CameraExid,
  ProjectExid,
  TimelineDateInterval,
  TimelineProviderRequestParams,
} from "@evercam/shared/types"
import moment from "moment-timezone"
import { TimelineDataProvider } from "./timelineDataProvider"

export class TimelineAnprProvider extends TimelineDataProvider {
  readonly projectExid: ProjectExid
  readonly cameraExid: CameraExid
  readonly token: string

  constructor(params: {
    timezone: string
    projectExid: ProjectExid
    cameraExid: CameraExid
    token: string
  }) {
    super(params.timezone)
    this.projectExid = params.projectExid
    this.cameraExid = params.cameraExid
    this.token = params.token
  }

  async fetchEvents({
    fromDate,
    toDate,
  }: TimelineDateInterval): Promise<Array<TimelineEvent>> {
    const params = {
      limit: 600,
      page: 1,
      plateNumber: "",
      direction: "",
      fromDate: this.formatTimestamp(fromDate),
      toDate: this.formatTimestamp(toDate),
      sort: "capture_time|desc",
      isPlate: true,
      isDuplicate: false,
    }

    const { items } = await AiApi.anpr.getAnprEvents(this.projectExid, params)

    return items.map((e) => ({
      ...e,
      timestamp: this.formatTimestamp(
        moment.tz(e.captureTime, "UTC").format().replace("Z", "+00:00")
      ),
      label: this.getFormattedPlateNumber(e.plateNumber),
      thumbnailUrl: `${axios.env.baseUrl}/cameras/${e.cameraex}/anpr/${e.id}/thumbnail?authorization=${this.token}`,
    }))
  }

  async fetchCounts({
    fromDate,
    toDate,
    precision,
  }: TimelineProviderRequestParams): Promise<Array<TimelineCountEvent>> {
    const items = await AiApi.anpr.getAnprCounts(this.projectExid, {
      fromDate: this.formatTimestamp(fromDate),
      toDate: this.formatTimestamp(toDate),
      isPlate: true,
      isDuplicate: false,
      precision,
    })

    const p = precision as moment.unitOfTime.Base

    let timelineCounts = items.map(({ date, counts }) => ({
      timestamp: this.formatTimestamp(date),
      startDate: moment(date).subtract(1, p).format(),
      endDate: moment(date).add(1, p).format(),
      count: (Object.values(counts) as number[]).reduce(
        (acc, count) => acc + count,
        0
      ),
    }))

    // Handle special case for single item counts for better visualization
    if (items.length === 1) {
      const item = timelineCounts[0]
      timelineCounts = [
        {
          startDate: moment(item.startDate).format(),
          timestamp: moment(item.startDate).format(),
          endDate: moment(item.startDate).format(),
          count: 0,
        },
        ...timelineCounts,
        {
          startDate: moment(item.endDate).format(),
          timestamp: moment(item.endDate).format(),
          endDate: moment(item.endDate).format(),
          count: 0,
        },
      ]
    }

    return timelineCounts
  }

  private getFormattedPlateNumber(rawPlateNumber: string): string {
    const irishPlateNumberRegex = new RegExp(
      /([0-9]{2}[1-2]?)([A-Z]+)([0-9]+)/
    ).exec(rawPlateNumber)
    if (!irishPlateNumberRegex) {
      return rawPlateNumber
    }

    return `${irishPlateNumberRegex[1]}-${irishPlateNumberRegex[2]}-${irishPlateNumberRegex[3]}`
  }
}
