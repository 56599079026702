
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default {
  name: "ImagePlayerMarkerTooltip",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    tooltipStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      minHeight: 135,
    }
  },
  methods: {
    onImageLoaded() {
      const imgHeight = this.$refs.vImg?.image?.height
      if (imgHeight) {
        this.minHeight = imgHeight
      }
    },
  },
}
