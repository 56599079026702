
import ImagePlayerMarkerTooltip from "@evercam/shared/components/imagePlayer/ImagePlayerMarkerTooltip"

export default {
  name: "ImagePlayerMarkers",
  components: {
    ImagePlayerMarkerTooltip,
  },
  props: {
    frames: {
      type: Array,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    showMarkerTooltip: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      isGateEventsLoading: false,
    }
  },
  computed: {
    activeEventsIndexes() {
      return this.events.reduce((acc, m, index) => {
        return m.isActive ? [...acc, index] : acc
      }, [])
    },
  },
  watch: {
    activeEventsIndexes(indexes) {
      if (indexes.length < 2) {
        return
      }
      this.$setTimeout(this.preventEventsThumbnailsOverlap, 500)
    },
  },
  created() {
    this.$root.$on(
      "is-gate-events-loading",
      (value) => (this.isGateEventsLoading = value)
    )
  },
  methods: {
    onMarkerMouseEnter(event) {
      this.$emit("marker-active", event)
    },
    onMarkerMouseLeave(event) {
      this.$emit("marker-reset", event)
    },
    onMarkerMouseUp(event) {
      this.$emit("marker-selected", event)
    },
    getMarkerClass(event, index) {
      return {
        "player-progress__marker--highlighted":
          this.activeEventsIndexes.includes(index),
        ...(event.cssClass || {}),
      }
    },
    getMarkerPosition({ frameIndex }) {
      const xPercent = (100 * frameIndex) / (this.frames.length - 1)
      const offset = xPercent > 2 ? 5 : 0

      return {
        left: `calc(${xPercent}% - ${offset}px)`,
      }
    },
    /**
     * When more than one event marker is displayed and highlighted
     * their corresponding thumbnail images may overlap if they're too close.
     * This function will prevent this overlap by applying a css transform
     */
    preventEventsThumbnailsOverlap() {
      const container = this.$refs.eventsMarkers
      const visibleThumbnails = Array.from(
        container?.querySelectorAll(
          ".player-progress__marker--highlighted .player-progress__marker-tooltip"
        )
      )

      if (visibleThumbnails.length !== 2) {
        return
      }

      const isProcessed =
        visibleThumbnails[0].style.left || visibleThumbnails[0].style.left
      if (isProcessed) {
        return
      }

      const thumbnailA = visibleThumbnails[0]?.getBoundingClientRect()
      const thumbnailB = visibleThumbnails[1]?.getBoundingClientRect()
      const intersectionRight = thumbnailA.right - thumbnailB.left
      const intersectionLeft = thumbnailB.right - thumbnailA.left
      if (intersectionRight <= 0 || intersectionLeft <= 0) {
        return
      }

      const padding = 5
      const isRightIntersection = intersectionRight > 0
      visibleThumbnails[isRightIntersection ? 0 : 1].style.left = `-${
        intersectionRight / 2 + padding
      }px`
      visibleThumbnails[isRightIntersection ? 1 : 0].style.left = `${
        intersectionRight / 2 + padding
      }px`
    },
  },
}
