import {
  CameraStatus,
  TimelapseActivityType,
  TimelinePrecision,
} from "@evercam/shared/types"
import { TimelineChartType } from "@evercam/ui"

export const MINUTE = 60 * 1000
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR
export const WEEK = 7 * DAY
export const MONTH = 30 * DAY
export const YEAR = 365 * DAY

export const TimelineSharedConfig = {
  label: "",
  height: 80,
  chartType: TimelineChartType.Milestones,
  dots: true,
  milestonesTransitionDuration: 300,
  milestonesLineHeight: 25,
}

export type TimelinePlayerBreakpoint = {
  precision: TimelinePrecision
  breakpoint: number
}

/**
 * Used to determine when to switch to a different precision, given the interval's length in days
 * example: if the interval length == 100, then the precision should be 'week':
 * because 100 > 45 ("week" breakpoint)
 * and 100 < 120 ("month" breakpoint)
 */
export const TLPlayerDefaultPrecisionBreakpoints: TimelinePlayerBreakpoint[] = [
  {
    precision: TimelinePrecision.Year,
    breakpoint: DAY * 732,
  },
  {
    precision: TimelinePrecision.Month,
    breakpoint: DAY * 120,
  },
  {
    precision: TimelinePrecision.Week,
    breakpoint: DAY * 45,
  },
  {
    precision: TimelinePrecision.Day,
    breakpoint: DAY * 15,
  },
  {
    precision: TimelinePrecision.Hour,
    breakpoint: DAY * 1.5,
  },
  {
    precision: TimelinePrecision.Events,
    breakpoint: 0,
  },
]

/**
 * Used to determine whether to fetch new data when the timeline visible interval changes.
 * example: if the precision == month and the visible interval changes by more than 2 months, then fetch the data.
 */
export const TLPlayerDefaultRefreshBreakpoints: TimelinePlayerBreakpoint[] = [
  {
    precision: TimelinePrecision.Year,
    breakpoint: YEAR * 2,
  },
  {
    precision: TimelinePrecision.Month,
    breakpoint: MONTH * 2,
  },
  {
    precision: TimelinePrecision.Week,
    breakpoint: WEEK * 2,
  },
  {
    precision: TimelinePrecision.Day,
    breakpoint: DAY * 2,
  },
  {
    precision: TimelinePrecision.Hour,
    breakpoint: HOUR * 2,
  },
  {
    precision: TimelinePrecision.Events,
    breakpoint: DAY * 0.5,
  },
]

/**
 * Used to determine the default chart type for a given precision
 */
export const TLPlayerDefaultChartTypeByPrecision = {
  [TimelinePrecision.Year]: TimelineChartType.LineGraph,
  [TimelinePrecision.Month]: TimelineChartType.LineGraph,
  [TimelinePrecision.Week]: TimelineChartType.LineGraph,
  [TimelinePrecision.Day]: TimelineChartType.LineGraph,
  [TimelinePrecision.Hour]: TimelineChartType.LineGraph,
  [TimelinePrecision.Minute]: TimelineChartType.Bars,
  [TimelinePrecision.Events]: TimelineChartType.Bars,
}

export const TimelineColors = {
  primary: "#629efc",
  primaryDark: "#1f77b4",
  success: "#42c01f",
  gateReport: "#1b77d2",
  motion: "#d36511",
  anpr: "#519640",
  exNvr: "#7a57ee",
  processingData: "#1f77b4",
  safetyReport: "#d36511",
  constructionReport: "#396e2c",
  copilot: "#c4282f",
  user: "#6b48c0",
  bimMilestones: "#E59BE9",
  compareMarker: "rgba(17,97,182,0.91)",
  droneMilestone: "rgba(37,77,122,0.91)",
  _360Milestone: "rgba(37,77,122,0.91)",
  mediaHubMilestone: "rgba(37,77,122,0.91)",
  mobileCaptureMilestone: "rgba(37,77,122,0.91)",
  comments: "rgba(17,68,182,0.91)",
  placeholder: "transparent",
  forbiddenInterval: "rgba(196,196,196,0.72)",
  error: "#673AB7",
  [CameraStatus.Decommissioned]: "#115e59",
  [CameraStatus.Offline]: "#dc2626",
  [CameraStatus.Online]: "#62ae4f",
  [CameraStatus.OfflineScheduled]: "#facc15",
  [CameraStatus.Waiting]: "#f97316",
  [CameraStatus.WaitingForSiteVisit]: "#a3a3a3",
  [CameraStatus.OnHold]: "#f43f5e",
  [CameraStatus.UnderMaintenance]: "#f87171",
  [TimelapseActivityType.Workers]: "#b5a830",
  [TimelapseActivityType.Equipment]: "#913151",
  [TimelapseActivityType.Vehicles]: "#224289",
  articulatedDumper: "#ba68c8",
  backhoeLoader: "#42a5f5",
  compactExcavator: "#ce93d8",
  concretePump: "#ffcc80",
  dumper: "#1976d2",
  excavator: "#1e88e5",
  flatbedSemiTrailer: "#ffd600",
  industrialTruck: "#fb8c00",
  loader: "#64b5f6",
  mewps: "#a5d6a7",
  mobileCrane: "#ffa726",
  mobileCrusher: "#81c784",
  otherTruck: "#ef5350",
  pileDrillingRig: "#4db6ac",
  roadVehicle: "#66bb6a",
  roller: "#80cbc4",
  semiTrailer: "#ffff8d",
  skidSteerLoader: "#ab47bc",
  skipTruck: "#135d17",
  smallTruck: "#ef9a9a",
  tankTruck: "#ffea00",
  teleHandler: "#f57c00",
  tippingTruck: "#43a047",
  towerCrane: "#ffb74d",
  towerMountedConcreteMix: "#ca6d16",
  tractorDozer: "#90caf9",
  truckHead: "#e57373",
  truckMixer: "#26a69a",
  truckMountedMobileCrane: "#a06f20",
  unknown: "#9e9e9e",
  worker: "#e53935",
  "Crane-A": "#1b77d2",
  "Crane-B": "#d36511",
  "Crane-C": "#519640",
  "Crane-D": "#607D8B",
  "Crane-E": "#E91E63",
  "Crane-F": "#FFEB3B",
  "Crane-G": "#3F51B5",
  "Crane-H": "#FFCDD2",
  "Mobile-Crane-A": "#673AB7",
  "Mobile-Crane-B": "#FF9800",
  "Mobile-Crane-C": "#795548",
  "Mobile-Crane-D": "#607D8B",
  "Mobile-Crane-E": "#9C27B0",
  "Mobile-Crane-F": "#00BCD4",
  "Mobile-Crane-G": "#009688",
  "Mobile-Crane-H": "#FFEB3B",
  airCompressor: "#ba68c8",
  brick: "#42a5f5",
  building: "#ce93d8",
  cableReel: "#ffcc80",
  cementMixer: "#1976d2",
  cementPile: "#1e88e5",
  concreteBarrier: "#ffd600",
  concreteBlock: "#fb8c00",
  constructionContainer: "#64b5f6",
  constructionSite: "#a5d6a7",
  crane: "#ffa726",
  detachedExcavatorBucket: "#81c784",
  dryMortarSilo: "#ef5350",
  dumpTruck: "#4db6ac",
  dumpster: "#66bb6a",
  fence: "#80cbc4",
  fireHydrant: "#ffff8d",
  garbageBin: "#ab47bc",
  hoseReel: "#135d17",
  ibc: "#ef9a9a",
  jerryCan: "#ffea00",
  metalBeam: "#f57c00",
  miniDumper: "#43a047",
  pallet: "#ffb74d",
  pipe: "#ca6d16",
  puddle: "#90caf9",
  rebar: "#e57373",
  rubble: "#26a69a",
  scaffolding: "#a06f20",
  shippingContainer: "#9e9e9e",
  siteOffice: "#e53935",
  trafficCone: "#1b77d2",
  transformer: "#d36511",
  vehicle: "#519640",
  window: "#607D8B",
  woodenSpool: "#E91E63",
}
