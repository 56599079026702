
import { timeouts } from "@evercam/ui"
import ImagePlayerMarkers from "@evercam/shared/components/imagePlayer/ImagePlayerMarkers"

export default {
  name: "PlayerProgressBar",
  components: { ImagePlayerMarkers },
  mixins: [timeouts],
  props: {
    frames: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    preloadedFrames: {
      type: Array,
      default: () => [],
    },
    frameIndex: {
      type: Number,
      default: 0,
    },
    ticks: {
      type: Boolean,
      default: true,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
      progressBar: null,
      isDraggingCursor: false,
      mousePosition: {
        x: 0,
        y: 0,
      },
    }
  },
  computed: {
    hoveredFrameData() {
      return this.frames[this.hoveredFrameIndex] || {}
    },
    mouseToWidthRatio() {
      return (
        this.mousePosition.x / this.progressBar?.getBoundingClientRect()?.width
      )
    },
    tooltipPosition() {
      let tooltipWidth = this.$vuetify.breakpoint.mdAndDown ? "65" : "155"
      let marginLeft
      if (this.mousePosition.x < tooltipWidth / 2) {
        marginLeft = "7%"
      } else if (
        this.progressBar?.getBoundingClientRect()?.width -
          this.mousePosition.x <
        tooltipWidth / 2
      ) {
        marginLeft = "93%"
      } else {
        marginLeft = `${100 * this.mouseToWidthRatio}%`
      }

      return {
        width: `${tooltipWidth}px`,
        left: marginLeft,
        pointerEvents: "none",
        bottom: "8px",
      }
    },
    hoverBarStyle() {
      return {
        width: `${100 * this.mouseToWidthRatio}%`,
      }
    },
    progressStyle() {
      const currentFrameIndex = this.isDraggingCursor
        ? this.hoveredFrameIndex
        : this.frameIndex

      if (this.isLive) {
        return "100%"
      }

      return {
        width: `${100 * (currentFrameIndex / (this.frames.length - 1))}%`,
      }
    },
    cursorStyle() {
      return {
        opacity: this.hovered ? 1 : 0,
      }
    },
    hoveredFrameIndex() {
      return Math.round(this.mouseToWidthRatio * (this.frames.length - 1))
    },
    preloadedChunks() {
      const preloadedFrames = [...this.preloadedFrames]
      preloadedFrames.sort((a, b) => {
        return a > b ? 1 : -1
      })

      return preloadedFrames.reduce((acc, frameIndex) => {
        if (!acc.length) {
          return [[frameIndex, frameIndex]]
        }

        const [start, end] = acc.slice(-1)[0] || [0, 0]
        const discontinuity = frameIndex - end > 1
        if (discontinuity) {
          return [...acc, [frameIndex, frameIndex]]
        }

        return [...acc.slice(0, -1), [start, frameIndex]]
      }, [])
    },
  },

  mounted() {
    this.$setTimeout(() => {
      this.updateRefs()
    })
  },
  methods: {
    getMousePosition(e) {
      if (!this.progressBar) {
        return { x: 0, y: 0 }
      }
      const progrssBarRect = this.progressBar?.getBoundingClientRect()

      return {
        x: e.clientX - progrssBarRect.x,
        y: e.clientY - progrssBarRect.y,
      }
    },
    onMouseEnter() {
      this.hovered = true
    },
    onMouseMove(e) {
      this.mousePosition = this.getMousePosition(e)
    },
    onMouseLeave() {
      this.hovered = false
      this.isDraggingCursor = false
    },
    onCursorMouseDown() {
      this.isDraggingCursor = true
    },
    onCursorMouseUp() {
      this.isDraggingCursor = false
    },
    onProgressBarClick() {
      this.$emit("update-frame-index", this.hoveredFrameIndex)
      this.$emit("seek", this.hoveredFrameIndex)
    },
    updateRefs() {
      this.progressBar = this.$refs.progressBar
    },
    getChunkStyle(start, end) {
      const [startPercentage, endPercentage] = [
        (100 * start) / (this.frames.length - 1),
        (100 * end) / (this.frames.length - 1),
      ]

      return {
        left: `${startPercentage}%`,
        width: `${endPercentage - startPercentage}%`,
      }
    },
  },
}
