
import Vue from "vue"

export default Vue.extend({
  name: "PlayPauseButton",
  props: {
    showTooltip: {
      type: Boolean,
      default: true,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
  },
})
