
import Vue from "vue"
import { StyleValue } from "@vue/runtime-dom"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default Vue.extend({
  name: "EdgeVideoThumbnailPreview",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    targetTimestamp: {
      type: [Date, String, undefined],
      default: undefined,
    },
    streamingToken: {
      type: String,
      default: "",
    },
    nvrConfig: {
      type: Object,
      required: true,
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9,
    },
    height: {
      type: Number,
      default: 175,
    },
  },
  data() {
    return {
      thumbnailWidth: 0,
      thumbnailHeight: 0,
      thumbnailsUrls: [],
      numberOfThumbnails: 0,
      bifArrayBuffer: new ArrayBuffer(0),
      placeholderUrl:
        "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
      isThumbnailsPreviewFetched: false,
      isLoading: false,
    }
  },
  computed: {
    targetThumbnailIndex(): number {
      if (this.bifArrayBuffer.byteLength === 0 || !this.targetTimestamp) {
        return 0
      }
      const targetTime = this.$moment(this.targetTimestamp)
      const startOfHour = this.$moment(targetTime).startOf("hour")
      const timeDiffInSeconds = targetTime.diff(startOfHour, "seconds")
      const thumbnailIndex = Math.floor(
        (timeDiffInSeconds / 3600) * this.numberOfThumbnails
      )

      if (thumbnailIndex < 0) {
        return 0
      } else if (thumbnailIndex >= this.numberOfThumbnails) {
        return this.numberOfThumbnails - 1
      }

      return thumbnailIndex
    },
    thumbnailStyle(): StyleValue {
      return {
        height: `${this.height}px`,
        maxHeight: `${this.height}px`,
        aspectRatio: this.aspectRatio,
      }
    },
    showThumbnailPreview() {
      return this.isThumbnailsPreviewFetched && !this.isLoading
    },
  },
  watch: {
    targetTimestamp: {
      immediate: true,
      async handler(timestamp, oldTimestamp) {
        const newHour = this.$moment(timestamp).startOf("hour").toString()
        const oldHour = this.$moment(oldTimestamp).startOf("hour").toString()
        if (newHour === oldHour && timestamp) {
          return
        }

        await this.downloadBifForCurrentHour()
        this.unpackBif()
      },
    },
  },
  methods: {
    async downloadBifForCurrentHour() {
      this.isLoading = true
      const hour = this.$moment
        .tz(this.targetTimestamp, this.timezone)
        .startOf("hour")
        .format("YYYY-MM-DDTHH:mm:ss[Z]")
      try {
        const { bifUrl } = this.nvrConfig
        const response = await fetch(`${bifUrl}/${hour}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.streamingToken}`,
          },
        })

        this.bifArrayBuffer = await response.arrayBuffer()
        if (!response.ok) {
          throw new Error(
            `Status: ${response.status} Response: ${response.statusText}`
          )
        }
        this.isThumbnailsPreviewFetched = true
      } catch (e) {
        console.error("Failed to download BIF file: ", e)
        this.isThumbnailsPreviewFetched = false
      } finally {
        this.isLoading = false
      }
    },
    unpackBif() {
      if (this.bifArrayBuffer.byteLength === 0) {
        return
      }

      const imageDataURLs = []
      try {
        const bufferDataView = new DataView(this.bifArrayBuffer)
        const headerSize = bufferDataView.getUint32(0, true)
        const indexOffset = 64
        this.numberOfThumbnails = bufferDataView.getUint32(12, true)

        for (let i = 0; i < this.numberOfThumbnails - 1; i++) {
          const offset = bufferDataView.getUint32(indexOffset + i * 8 + 4, true)
          const nextOffset = bufferDataView.getUint32(
            indexOffset + (i + 1) * 8 + 4,
            true
          )
          const imageDataLength = nextOffset - offset
          const imageData = new Uint8Array(
            this.bifArrayBuffer.slice(offset, offset + imageDataLength)
          )
          const base64Image = btoa(String.fromCharCode.apply(null, imageData))
          const dataURL = "data:image/png;base64," + base64Image

          imageDataURLs.push(dataURL)
        }

        const lastImageOffset = headerSize
        const lastImageData = new Uint8Array(
          this.bifArrayBuffer.slice(lastImageOffset)
        )
        const lastBase64Image = btoa(
          String.fromCharCode.apply(null, lastImageData)
        )
        const lastDataURL = `data:image/png;base64,${lastBase64Image}`

        imageDataURLs.push(lastDataURL)
      } catch (e) {
        console.error("Failed to unpack the BIF data.", e)
      }

      this.thumbnailsUrls = imageDataURLs
    },
  },
})
